$(".nav-1").on("click", function () {
  $.scrollify.move("#2");
});

$(".nav-2").on("click", function () {
  $.scrollify.move("#3");
});

$(".nav-3").on("click", function () {
  $.scrollify.move("#6");
});

$(".nav-4").on("click", function () {
  $.scrollify.move("#9");
});

$(".nav-5").on("click", function () {
  $.scrollify.move("#10");
});

$(".scroll-down").on("click", function () {
  $.scrollify.move("#2");
});

$(".features_1").on("click", function () {
  $.scrollify.move("#3");
})

$(".features_2").on("click", function () {
  $.scrollify.move("#4");
})
$(".features_3").on("click", function () {
  $.scrollify.move("#5");
})

$(".instruction_1").on("click", function () {
  $.scrollify.move("#6")
})

$(".instruction_2").on("click", function () {
  $.scrollify.move("#7")
})

$(".instruction_3").on("click", function () {
  $.scrollify.move("#8")
})