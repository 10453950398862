(function () {
  let mainBurger = document.querySelector(".burger");
  let burgerLines = document.querySelector(".burger__lines");
  let burgerClose = document.querySelector(".burger__close-svg");
  let burgerContainer = document.querySelector(".burger__container");
  burgerLines.addEventListener("click", function () {
    mainBurger.classList.toggle("active");
    burgerContainer.classList.toggle("active");
    document.querySelector("html").style.overflow = "hidden";
    $.scrollify.disable();
  });
  burgerClose.addEventListener("click", function () {
    mainBurger.classList.remove("active");
    burgerContainer.classList.remove("active");
    document.querySelector("html").style.overflow = "auto";
    $.scrollify.enable();
  });

  if (window.innerWidth > 1100) {
    $(".burger_1").on("click", function () {
      $.scrollify.enable();
      mainBurger.classList.remove("active");
      burgerContainer.classList.remove("active");
      document.querySelector("html").style.overflow = "auto";
      $.scrollify.move("#2");
    });

    $(".burger_2").on("click", function () {
      $.scrollify.enable();
      mainBurger.classList.remove("active");
      burgerContainer.classList.remove("active");
      document.querySelector("html").style.overflow = "auto";
      $.scrollify.move("#3");
    });

    $(".burger_3").on("click", function () {
      $.scrollify.enable();
      mainBurger.classList.remove("active");
      burgerContainer.classList.remove("active");
      document.querySelector("html").style.overflow = "auto";
      $.scrollify.move("#6");
    });

    $(".burger_4").on("click", function () {
      $.scrollify.enable();
      mainBurger.classList.remove("active");
      burgerContainer.classList.remove("active");
      document.querySelector("html").style.overflow = "auto";
      $.scrollify.move("#9");
    });

    $(".burger_5").on("click", function () {
      $.scrollify.enable();
      mainBurger.classList.remove("active");
      burgerContainer.classList.remove("active");
      document.querySelector("html").style.overflow = "auto";
      $.scrollify.move("#10");
    });
  } else {
    $(".burger_1").on("click", function () {
      mainBurger.classList.remove("active");
      burgerContainer.classList.remove("active");
      document.querySelector("html").style.overflow = "auto";
    });
    $(".burger_2").on("click", function () {
      mainBurger.classList.remove("active");
      burgerContainer.classList.remove("active");
      document.querySelector("html").style.overflow = "auto";
    });
    $(".burger_3").on("click", function () {
      mainBurger.classList.remove("active");
      burgerContainer.classList.remove("active");
      document.querySelector("html").style.overflow = "auto";
    });
    $(".burger_4").on("click", function () {
      mainBurger.classList.remove("active");
      burgerContainer.classList.remove("active");
      document.querySelector("html").style.overflow = "auto";
    });
    $(".burger_5").on("click", function () {
      mainBurger.classList.remove("active");
      burgerContainer.classList.remove("active");
      document.querySelector("html").style.overflow = "auto";
    });
  }
})();
