window.addEventListener(
  "resize",
  function () {
    console.log(innerWidth);

    if (innerWidth <= 1100) {
      $.scrollify.disable();
    } else {
      $.scrollify.enable();
    }
  },
  false
);

if (window.innerWidth <= 1100) {
  $.scrollify.disable();

  window.onload = function () {
    headerAnimate();
  };
} else {
  $.scrollify.enable();
}

// // window.addEventListener("skroll")
// document.getElementById("headerAnimate").scrollIntoView();
