// $(".instruction_1").on("click", function () {
//   gsap.to(".instruction__image_1", {
//     duration: 0.7,
//     y: 0,
//   });
//   gsap.to(".instruction__image_2", {
//     duration: 0.7,
//     y: 1500,
//   });
//   gsap.to(".instruction__image_3", {
//     duration: 0.7,
//     y: 2500,
//   });

//   $(".instruction_1").addClass("active");
//   $(".instruction_2").removeClass("active");
//   $(".instruction_3").removeClass("active");
// });

// $(".instruction_2").on("click", function () {
//   gsap.to(".instruction__image_1", {
//     duration: 0.7,
//     y: -1500,
//   });
//   gsap.to(".instruction__image_2", {
//     duration: 0.7,
//     y: 0,
//   });
//   gsap.to(".instruction__image_3", {
//     duration: 0.7,
//     y: 1500,
//   });

//   $(".instruction_2").addClass("active");
//   $(".instruction_1").removeClass("active");
//   $(".instruction_3").removeClass("active");
// });

// $(".instruction_3").on("click", function () {
//   gsap.to(".instruction__image_1", {
//     duration: 0.7,
//     y: -2500,
//   });
//   gsap.to(".instruction__image_2", {
//     duration: 0.7,
//     y: -1500,
//   });
//   gsap.to(".instruction__image_3", {
//     duration: 0.7,
//     y: 0,
//   });

//   $(".instruction_3").addClass("active");
//   $(".instruction_2").removeClass("active");
//   $(".instruction_1").removeClass("active");
// });


