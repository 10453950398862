// console.log($("#about").scrollTop(function(){return this.scrollHeight}));
// console.log($(document).scrollTop());
// gsap.to(".about__image", {
//   scrollTrigger: ".about__image", // start the animation when ".box" enters the viewport (once)
//   markers: true,
//   duration: 0.5,
//   start: "top top",
//   x: 0,
//   opacity: 1,
// });

if (window.innerWidth <= 1100) {
  let about = gsap.timeline({
    scrollTrigger: {
      trigger: ".about",
      start: "center bottom",
      end: "center top",
      scrub: true,
    },
  });

  about.to(".about__image", { duration: 0.5, x: 0, opacity: 1 });

  let features = gsap.timeline({
    scrollTrigger: {
      trigger: ".features",
      start: "center bottom",
      end: "center top",
      scrub: true,
    },
  });

  features.to(".features__image", { duration: 0.5, x: 0, opacity: 1 });

  let instruction = gsap.timeline({
    scrollTrigger: {
      trigger: ".instruction",
      start: "center bottom",
      end: "center top",
      scrub: true,
    },
  });

  instruction.to(".instruction__image", { duration: 0.5, x: 0, opacity: 1 });

  let getStarted = gsap.timeline({
    scrollTrigger: {
      trigger: ".getStarted",
      start: "center bottom",
      end: "center top",
      scrub: true,
    },
  });

  getStarted.to(".getStarted__image", { duration: 0.5, x: 0, opacity: 1 });

  let news = gsap.timeline({
    scrollTrigger: {
      trigger: ".news",
      start: "top center",
      end: "top",
      scrub: true,
    },
  });

  news.to(".news__image", { duration: 0.5, x: 0, opacity: 1 });

  let features1 = gsap.timeline({
    scrollTrigger: {
      trigger: ".features_1",
      start: "center bottom",
      end: "center top",
      scrub: true,
    },
  });

  features1.to(".features__box-image1", { duration: 0.5, x: 0, opacity: 1 });

  let features2 = gsap.timeline({
    scrollTrigger: {
      trigger: ".features_2",
      start: "center bottom",
      end: "center top",
      scrub: true,
    },
  });

  features2.to(".features__box-image2", { duration: 0.5, x: 0, opacity: 1 });

  let features3 = gsap.timeline({
    scrollTrigger: {
      trigger: ".features_3",
      start: "center bottom",
      end: "center top",
      scrub: true,
    },
  });

  features3.to(".features__box-image3", { duration: 0.5, x: 0, opacity: 1 });

  let instruction1 = gsap.timeline({
    scrollTrigger: {
      trigger: ".instruction_1",
      start: "center bottom",
      end: "center top",
      scrub: true,
    },
  });

  instruction1.to(".instruction__mobile-image1", { duration: 0.5, x: 0, opacity: 1 });

  let instruction2 = gsap.timeline({
    scrollTrigger: {
      trigger: ".instruction_2",
      start: "center bottom",
      end: "center top",
      scrub: true,
    },
  });

  instruction2.to(".instruction__mobile-image2", { duration: 0.5, x: 0, opacity: 1 });

  let instruction3 = gsap.timeline({
    scrollTrigger: {
      trigger: ".instruction_3",
      start: "center bottom",
      end: "center top",
      scrub: true,
    },
  });

  instruction3.to(".instruction__mobile-image3", { duration: 0.5, x: 0, opacity: 1 });
}
