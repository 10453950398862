// $(".features_1").on("click", function () {
//   gsap.to(".features__image_1", {
//     duration: 0.7,
//     y: 0,
//     opacity: 1,
//   });
//   gsap.to(".features__image_2", {
//     duration: 0.7,
//     y: 1500,
//   });
//   gsap.to(".features__image_3", {
//     duration: 0.7,
//     y: 2500,
//   });

// });

// $(".features_2").on("click", function () {
//   gsap.to(".features__image_1", {
//     duration: 0.7,
//     y: -1500,
//   });
//   gsap.to(".features__image_2", {
//     duration: 0.7,
//     y: 0,
//   });
//   gsap.to(".features__image_3", {
//     duration: 0.7,
//     y: 1500,
//   });

//   $(".features_2 .features__box-title").addClass("active");
//   $(".features_1 .features__box-title").removeClass("active");
//   $(".features_3 .features__box-title").removeClass("active");
// });

// $(".features_3").on("click", function () {
//   gsap.to(".features__image_1", {
//     duration: 0.7,
//     y: -2500,
//   });
//   gsap.to(".features__image_2", {
//     duration: 0.7,
//     y: -1500,
//   });
//   gsap.to(".features__image_3", {
//     duration: 0.7,
//     y: 0,
//   });

//   $(".features_3 .features__box-title").addClass("active");
//   $(".features_2 .features__box-title").removeClass("active");
//   $(".features_1 .features__box-title").removeClass("active");
// });

