function headerAnimate() {
  gsap.to(".header__title", {
    duration: 0.7,
    y: 0,
    opacity: 1,
  });
  gsap
    .to(".header__animate-phone", {
      duration: 0.7,
      skewY: 0,
      skewX: 0,
      rotate: 0,
      scale: 1,
      opacity: 1,
    })
    .delay(0.2);
  gsap.to(".header__animate-data", {
    duration: 0.7,
    opacity: 1,
  });
  gsap
    .to(".header__animate-lines", { duration: 0.7, x: 0, opacity: 1 })
    .delay(0.4);
}

if (window.innerWidth > 1100) {
  if (window.innerWidth <= 1100) {
    $.scrollify.disable();
  } else {
    $(function () {
      $.scrollify({
        section: ".s-scroll",
        updateHash: true,
        scrollSpeed: 800,
        // overflowScroll: false,
        setHeights: true,
        after: function (e) {
          console.log(e);
          switch (e) {
            case 0:
              headerAnimate();
              break;
            case 1:
              gsap.to(".about__image", {
                duration: 0.5,
                x: 0,
                opacity: 1,
              });
              $(".features .nav__dots-container").removeClass("fixed");
              $(".features-orange").removeClass("fixed");
              gsap.to(".features__texts", {
                duration: 0.1,
                opacity: 0,
              });
              gsap.to(".features .nav__dots", {
                duration: 0.1,
                opacity: 0,
              });

              break;
            case 2:
              gsap.to(".features__image_1", {
                duration: 0.5,
                opacity: 1,
                x: 0,
              });
              gsap.to(".features__texts", {
                duration: 0.5,
                opacity: 1,
              });
              gsap.to(".features .nav__dots", {
                duration: 0.5,
                opacity: 1,
              });

              $(".features .nav__dots-container").addClass("fixed");
              $(".features-orange").addClass("fixed");

              break;
            case 3:
              gsap.to(".features__image_2", {
                duration: 0.2,
                y: 0,
              });

              $(".features .nav__dots-container").addClass("fixed");
              $(".features-orange").addClass("fixed");
              gsap.to(".features__texts", {
                duration: 0.1,
                opacity: 1,
              });
              gsap.to(".features .nav__dots", {
                duration: 0.1,
                opacity: 1,
              });
              break;

            case 4:
              // remove animate
              $(".instruction .nav__dots-container").removeClass("fixed");
              $(".instruction-orange").removeClass("fixed");
              gsap.to(".instruction__texts", {
                duration: 0.1,
                opacity: 0,
              });
              gsap.to(".instruction .nav__dots", {
                duration: 0.1,
                opacity: 0,
              });

              // animate
              gsap.to(".features__image_3", {
                duration: 0.5,
                opacity: 1,
                x: 0,
              });

              $(".features .nav__dots-container").addClass("fixed");
              $(".features-orange").addClass("fixed");
              gsap.to(".features__texts", {
                duration: 0.1,
                opacity: 1,
              });
              gsap.to(".features .nav__dots", {
                duration: 0.1,
                opacity: 1,
              });
              break;

            case 5:
              $(".features .nav__dots-container").removeClass("fixed");
              $(".features-orange").removeClass("fixed");

              gsap.to(".features__texts", {
                duration: 0.1,
                opacity: 0,
              });
              gsap.to(".features .nav__dots", {
                duration: 0.1,
                opacity: 0,
              });

              // animate

              gsap.to(".instruction__image_1", {
                duration: 0.5,
                opacity: 1,
                x: 0,
              });
              gsap.to(".instruction__texts", {
                duration: 0.5,
                opacity: 1,
              });
              gsap.to(".instruction .nav__dots", {
                duration: 0.5,
                opacity: 1,
              });

              $(".instruction .nav__dots-container").addClass("fixed");
              $(".instruction-orange").addClass("fixed");
              break;

            case 6:
              gsap.to(".instruction__image_2", {
                duration: 0.2,
                y: 0,
              });

              $(".instruction .nav__dots-container").addClass("fixed");
              $(".instruction .instruction-orange").addClass("fixed");
              gsap.to(".instruction__texts", {
                duration: 0.1,
                opacity: 1,
              });
              gsap.to(".instruction .nav__dots", {
                duration: 0.1,
                opacity: 1,
              });
              break;

            case 7:
              // animate
              gsap.to(".instruction__image_3", {
                duration: 0.5,
                opacity: 1,
                x: 0,
              });

              $(".instruction .nav__dots-container").addClass("fixed");
              $(".instruction-orange").addClass("fixed");
              gsap.to(".instruction__texts", {
                duration: 0.1,
                opacity: 1,
              });
              gsap.to(".instruction .nav__dots", {
                duration: 0.1,
                opacity: 1,
              });
              break;

            case 8:
              $(".instruction .nav__dots-container").removeClass("fixed");
              $(".instruction-orange").removeClass("fixed");

              gsap.to(".instruction__texts", {
                duration: 0.1,
                opacity: 0,
              });
              gsap.to(".instruction .nav__dots", {
                duration: 0.1,
                opacity: 0,
              });

              gsap.to(".getStarted__image", {
                duration: 0.5,
                x: 0,
                opacity: 1,
              });
              break;
            case 9:
              gsap.to(".news__image", {
                duration: 0.5,
                x: 0,
                opacity: 1,
              });
              break;

            default:
              break;
          }
        },
        before: function (e) {
          //   $('html, body').css({
          //     overflow: 'hidden',
          // });
          gsap.to(".about__image", {
            duration: 1,
            x: 50,
            opacity: 0,
          });

          gsap.to(".news__image", {
            duration: 0.5,
            x: 50,
            opacity: 0,
          });

          gsap.to(".getStarted__image", {
            duration: 0.5,
            x: 50,
            opacity: 0,
          });

          if (e == 1) {
            $(".features .nav__dots-container").removeClass("fixed");
            $(".features-orange").removeClass("fixed");

            gsap.to(".features__image_1", {
              duration: 1,
              opacity: 0,
              x: 50,
            });
            gsap.to(".features__texts", {
              duration: 0.1,
              opacity: 0,
            });
            gsap.to(".features .nav__dots", {
              duration: 0.1,
              opacity: 0,
            });
          }
          if (e !== 2 || e !== 3 || e !== 4) {
            gsap.to(".features__texts", {
              duration: 0.1,
              opacity: 0,
            });
            gsap.to(".features .nav__dots", {
              duration: 0.1,
              opacity: 0,
            });

            $(".features .nav__dots-container").removeClass("fixed");
            $(".features-orange").removeClass("fixed");
          }

          if (e !== 5 || e !== 6 || e !== 7) {
            console.log('1234');
            gsap.to(".instruction__texts", {
              duration: 0.2,
              opacity: 0,
            });
            gsap.to(".instruction .nav__dots", {
              duration: 0.2,
              opacity: 0,
            });

            $(".instruction .nav__dots-container").removeClass("fixed");
            $(".instruction-orange").removeClass("fixed");
          } 

          if (e === 3) {
            gsap.to(".features__image_1", {
              duration: 1,
            });

            $(".features_1 .features__box-title").removeClass("active");
            $(".features_2 .features__box-title").addClass("active");
            $(".features_3 .features__box-title").removeClass("active");
          }

          if (e === 4) {
            gsap.to(".features__image_2", {
              duration: 0.2,
            });

            $(".features_1 .features__box-title").removeClass("active");
            $(".features_2 .features__box-title").removeClass("active");
            $(".features_3 .features__box-title").addClass("active");

            // instruction

            $(".instruction .nav__dots-container").removeClass("fixed");
            $(".instruction-orange").removeClass("fixed");

            gsap.to(".instruction__image_1", {
              duration: 1,
              opacity: 0,
              x: 50,
            });
            gsap.to(".instruction__texts", {
              duration: 0.1,
              opacity: 0,
            });
            gsap.to(".instruction .nav__dots", {
              duration: 0.1,
              opacity: 0,
            });
          }
          if (e === 2) {
            $(".features .nav__dots-container").addClass("fixed");
            $(".features-orange").addClass("fixed");

            gsap.to(".features__image_2", {
              duration: 0.2,
            });

            gsap.to(".instruction__texts", {
              duration: 0.1,
              opacity: 0,
            });
            gsap.to(".instruction .nav__dots", {
              duration: 0.1,
              opacity: 0,
            });

            $(".features_1 .features__box-title").addClass("active");
            $(".features_2 .features__box-title").removeClass("active");
            $(".features_3 .features__box-title").removeClass("active");
          } else if (e === 5) {
            gsap.to(".features__image_3", {
              duration: 0.2,
              opacity: 0,
              x: 50,
            });

            gsap.to(".features__texts", {
              duration: 0.1,
              opacity: 0,
            });
            gsap.to(".features .nav__dots", {
              duration: 0.1,
              opacity: 0,
            });

            $(".features .nav__dots-container").removeClass("fixed");
            $(".features-orange").removeClass("fixed");
            gsap.to(".features__texts", {
              duration: 0.1,
              opacity: 0,
            });
            gsap.to(".features .nav__dots", {
              duration: 0.1,
              opacity: 0,
            });

            gsap.to(".instruction__image_1", {
              duration: 1,
            });

            $(".instruction_1").addClass("active");
            $(".instruction_2").removeClass("active");
            $(".instruction_3").removeClass("active");
          }

          if (e === 6) {
            $(".instruction_1").removeClass("active");
            $(".instruction_2").addClass("active");
            $(".instruction_3").removeClass("active");
          }

          if (e === 7) {
            $(".instruction_1").removeClass("active");
            $(".instruction_2").removeClass("active");
            $(".instruction_3").addClass("active");
          }

          if (e === 8) {
            gsap.to(".instruction__image_3", {
              duration: 0.2,
              opacity: 0,
              x: 50,
            });

            gsap.to(".instruction__texts", {
              duration: 0.1,
              opacity: 0,
            });
            gsap.to(".instruction .nav__dots", {
              duration: 0.1,
              opacity: 0,
            });

            $(".instruction .nav__dots-container").removeClass("fixed");
            $(".instruction-orange").removeClass("fixed");
            gsap.to(".instruction__texts", {
              duration: 0.1,
              opacity: 0,
            });
            gsap.to(".instruction .nav__dots", {
              duration: 0.1,
              opacity: 0,
            });
          }
          gsap.to(".header__title", {
            duration: 1,
            y: 100,
            opacity: 0,
          });
          gsap.to(".header__animate-data", {
            duration: 1,
            opacity: 0,
          });
          gsap.to(".header__animate-phone", {
            duration: 1,
            skewY: -20,
            skewX: 0,
            rotate: 0,
            scale: 1,
            opacity: 0,
          });
          gsap
            .to(".header__animate-lines", { duration: 1, x: 300, opacity: 0 })
            .delay(0.4);
        },
        afterRender: function () {
          headerAnimate();
        },
        afterResize: function () {},
      });
    });
  }

  // $(document).scroll(function () {
  //   let s_top = $(document).scrollTop();
  //   let yes = $("#heightFeatures").offset().top;
  //   let intruction = $("#instruction").offset().top;
  //   let getStarted = $("#getStarted").offset().top;
  //   if (s_top >= getStarted) {
  //     $(".instruction").removeClass("fixed");
  //     $(".instruction").addClass("end");
  //   } else {
  //     $(".instruction").addClass("fixed");
  //     $(".instruction").removeClass("end");
  //   }
  //   if (s_top >= intruction) {
  //     $(".features").removeClass("fixed");
  //     $(".features").addClass("end");
  //     $(".instruction").addClass("fixed");

  //     if (s_top >= intruction && s_top <= intruction + 200) {
  //       gsap.to(".instruction__image_1", {
  //         duration: 0.7,
  //         y: 0,
  //       });
  //       gsap.to(".instruction__image_2", {
  //         duration: 0.7,
  //         y: 1500,
  //       });
  //       gsap.to(".instruction__image_3", {
  //         duration: 0.7,
  //         y: 2500,
  //       });

  //       $(".instruction_1").addClass("active");
  //       $(".instruction_2").removeClass("active");
  //       $(".instruction_3").removeClass("active");
  //     }

  //     if (s_top >= intruction + 200 && s_top <= intruction + 400) {
  //       gsap.to(".instruction__image_1", {
  //         duration: 0.7,
  //         y: -1500,
  //       });
  //       gsap.to(".instruction__image_2", {
  //         duration: 0.7,
  //         y: 0,
  //       });
  //       gsap.to(".instruction__image_3", {
  //         duration: 0.7,
  //         y: 1500,
  //       });

  //       $(".instruction_2").addClass("active");
  //       $(".instruction_1").removeClass("active");
  //       $(".instruction_3").removeClass("active");
  //     }
  //     if (s_top >= intruction + 400 && s_top <= intruction + 600) {
  //       gsap.to(".instruction__image_1", {
  //         duration: 0.7,
  //         y: -2500,
  //       });
  //       gsap.to(".instruction__image_2", {
  //         duration: 0.7,
  //         y: -1500,
  //       });
  //       gsap.to(".instruction__image_3", {
  //         duration: 0.7,
  //         y: 0,
  //       });

  //       $(".instruction_3").addClass("active");
  //       $(".instruction_2").removeClass("active");
  //       $(".instruction_1").removeClass("active");
  //     }
  //   } else {
  //     $(".features").addClass("fixed");
  //     $(".features").removeClass("end");
  //     $(".instruction").removeClass("fixed");
  //   }
  //   if (s_top >= yes) {
  //     $(".features").addClass("fixed");

  //     if (s_top >= yes && s_top <= yes + 200) {
  //       gsap.to(".features__image_1", {
  //         duration: 0.7,
  //         y: 0,
  //         opacity: 1,
  //       });
  //       gsap.to(".features__image_2", {
  //         duration: 0.7,
  //         y: 1500,
  //       });
  //       gsap.to(".features__image_3", {
  //         duration: 0.7,
  //         y: 2500,
  //       });

  //       $(".features_1 .features__box-title").addClass("active");
  //       $(".features_2 .features__box-title").removeClass("active");
  //       $(".features_3 .features__box-title").removeClass("active");
  //     }
  //     if (s_top >= yes + 200 && s_top <= yes + 400) {
  //       gsap.to(".features__image_1", {
  //         duration: 0.7,
  //         y: -1500,
  //       });
  //       gsap.to(".features__image_2", {
  //         duration: 0.7,
  //         y: 0,
  //       });
  //       gsap.to(".features__image_3", {
  //         duration: 0.7,
  //         y: 1500,
  //       });

  //       $(".features_2 .features__box-title").addClass("active");
  //       $(".features_1 .features__box-title").removeClass("active");
  //       $(".features_3 .features__box-title").removeClass("active");
  //     }

  //     if (s_top >= yes + 400 && s_top <= yes + 600) {
  //       gsap.to(".features__image_1", {
  //         duration: 0.7,
  //         y: -2500,
  //       });
  //       gsap.to(".features__image_2", {
  //         duration: 0.7,
  //         y: -1500,
  //       });
  //       gsap.to(".features__image_3", {
  //         duration: 0.7,
  //         y: 0,
  //       });

  //       $(".features_3 .features__box-title").addClass("active");
  //       $(".features_2 .features__box-title").removeClass("active");
  //       $(".features_1 .features__box-title").removeClass("active");
  //     }
  //   } else {
  //     $(".features").removeClass("fixed");
  //   }
  // });
}
